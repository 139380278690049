
/**
 * @name: 商品管理-货架管理
 * @author:lili
 * @date: 2024-05-14 14:37
 * @description： 商品管理-货架管理
 * @update: 2024-05-14 14:37
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import config from "@/config";
import {sortPageApi} from "@/apis/product/sort";
import {
  shelvesProductDetailApi, shelvesProductExportApi,
  shelvesProductModifySortingApi, shelvesProductModifyStatusApi,
  shelvesProductPageApi
} from "@/apis/product/shelves";
import {exportFile} from "@/utils/common";
import {IProductSort} from "@/apis/product/sort/types";
import {IProduct, IProductParams} from "@/apis/product/shelves/types";
import {Message} from "element-ui";

@Component({})
export default class productShelves extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IProductParams = {
    page: 1,
    limit: 10,
    isProductShelve:1//是否在货架: 1=是 2=否
  }
  // 表单参数
  modelForm: Partial<IProduct> = {}
  sortTreeList: IProductSort[] = []
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    menu: true,
    editBtn: true,
    delBtn: false,
    addTitle: "新增商品",
    editTitle: "编辑商品",
    labelWidth: "150px",
    column: [
      {
        label: "商品",
        prop: "productName",
        placeholder: "商品编号/商品名称",
        align: 'center',
        search: true,
        hide: true,
        addHide: true,
        editHide: true,
        viewHide: true
      },
      {
        label: "商品编号",
        prop: "id",
        align: 'center',
        addHide: true,
        editHide: true,
        slot: true,
        width: 200,
      },
      {
        label: "商品名称",
        prop: "productName",
        align: 'center',
        width: 150,
        overHidden:true,
      },
      {
        label: "商品分类",
        prop: "sortName",
        align: 'center',
        width:150,
        overHidden:true,
      },
      {
        label: "商品分类",
        prop: "sortId",
        searchSlot: true,
        align: 'center',
        hide: true,
        viewHide: true,
        search: true,
        span: 12,
      },
      {
        label: "渠道来源",
        prop: "supplyType",
        align: "center",
        width: 150,
        type: "select",
        span: 12,
        disabled: true,
        search: true,
        dicData: [
          {
            label: "京东",
            value: 1
          },
          {
            label: "星链",
            value: 2
          },
          {
            label: "平台测试",
            value: 3
          },
          {
            label: "云商特卖",
            value: 4
          },
          {
            label: "厂商特卖",
            value: 5
          },
          {
            label: "天猫",
            value: 6
          },
          {
            label: "微唯宝特卖",
            value: 7
          },
          {
            label: "云链专供",
            value: 8
          },
          {
            label: "三方仓储",
            value: 9
          },
          {
            label: "华南一仓",
            value: 10
          },
          {
            label: "华南二仓",
            value: 11
          },
          {
            label: "华南二仓",
            value: 12
          },
          {
            label: "3C家电",
            value: 13
          },
          {
            label: "新疆专场",
            value: 14
          },
          {
            label: "西藏专场",
            value: 15
          },
          {
            label: "内蒙专场",
            value: 16
          },
          {
            label: "华东一仓",
            value: 17
          },
          {
            label: "企业专属",
            value: 18
          },
          {
            label: "云采渠道",
            value: 19
          },
          {
            label: "华东二仓",
            value: 20
          },
          {
            label: "华东三仓",
            value: 21
          },
          {
            label: "自营",
            value: 88
          }
        ]
      },
      {
        label: "已售",
        prop: "saleQty",
        align: 'center',
        span: 12,
      },
      {
        label: "限购数量",
        prop: "quotaQty",
        align: 'center',
        span: 12,
      },

      {
        label: "成本价（元）",
        prop: "costPrice",
        align: 'center',
        width: 150,
        span: 12,
      },
      {
        label: "零售价（元）",
        prop: "retailPrice",
        align: 'center',
        width: 150,
        span: 12,
      },
      {
        label: "标签",
        prop: "label",
        hide:true,
        span: 24,
      },
      {
        label: "商品主图",
        prop: "productPic",
        type: 'image',
        align: 'center',
        span: 24,
        width: 150,
      },
      {
        label: "商品详情图",
        prop: "productDtlPic",
        type: 'image',
        align: 'center',
        hide: true,
        span: 24,
      },
      {
        label: "排序",
        prop: "sort",
        viewHide: true,
        align: "center",
        slot: true
      },
      {
        label: "贡献值结算时间节点",
        prop: "settlementNode",
        align: 'center',//结算节点: 1=付款后 2=收货后
        type:'select',
        dicData: [
          {
            label: "确认收货后",
            value: 2
          },
          {
            label: "付款后即时",
            value: 1
          },
        ],
        hide: true,
        span: 12,
      },
      // {
      //   label: "限购数量",
      //   prop: "quotaQty",
      //   align: 'center',
      //   hide: true,
      //   span: 12,
      // },
      {
        label: "销售时间",
        prop: "saleTime",
        align: "center",
        width:145,
        span: 12,
        slot: true,
        viewSlot:true,
      },
      {
        label: "banner宣传图",
        prop: "bannerPic",
        type: 'image',
        align: 'center',
        hide: true,
        span: 24,
      },
      {
        label: "物流运费(元)",
        prop: "postagePrice",
        align: 'center',
        hide: true,
        span: 12,
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        search: true,
        type: 'switch',
        slot: true,
        width:100,
        dicData: [
          {
            label: "下架",
            value: 2
          },
          {
            label: "上架",
            value: 1
          },
        ],
        viewHide: true,
      },
    ]
  }
  queryParamSku: IProductParams = {
    page: 1,
    limit: 10,
  }
  skuForm:any={}
  tableDataSku:any=[];
  specTableLoading = false
  tableTotalSku:number=0;
  // crud配置信息
  crudOptionSku: ICrudOption = {
    searchBox: false,
    menu: false,
    editTitle: "",
    labelWidth: "130px",
    column: [
      {
        label: "sku编号",
        prop: "id",
        align: 'center',
        width: 150,
      },
      {
        label: "规格属性",
        prop: "spData",
        align: 'center',
        slot: true,
        width:200,
        span: 12,
      },
      {
        label: "规格轮播图",
        prop: "skuPic",
        type: 'image',
        align: 'center',
        width: 200,
        imgPrefix: config.downloadUrl,
        span: 24,
      },

      {
        label: "成本价(元)",
        prop: "costPrice",
        align: 'center',
        width: 100,
        span: 12,
      },
      {
        label: "零售价(元)",
        prop: "retailPrice",
        align: 'center',
        width: 100,
        span: 12,
      },
      {
        label: "可售库存",
        prop: "stock",
        align: 'center',
        span: 12,
      },
      {
        label: "全网贡献值分成",
        prop: "type",
        align: 'center',
        width:145,
        slot: true,
      },
    ]
  }

  visibleDialog: boolean = false
  typeTableData: any = [
    {
      name: '消费者',
      value: 0.000000
    },
    {
      name: 'VIP会员（VIP1—VIP7）',
      value: 0.000000
    },
    {
      name: '分享者',
      value: 0.000000
    },
    {
      name: '商家',
      value: 0.000000
    },
    {
      name: '城市合伙人',
      value: 0.000000
    },
    {
      name: '社区合伙人',
      value: 0.000000
    }
  ]

  // 编辑商品排序弹窗
  editSortDialog = false
  // 编辑商品排序表单
  editSortForm = {
    id: '',
    sort: ''
  }
  /**
   * 列表查询
   */
  getList() {
    this.queryParam.isProductShelve=1;
    shelvesProductPageApi(this.queryParam).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total
      this.tableLoading = false;
    })
  }

  /**
   * 获取商品分类列表
   * @param status 显示状态
   */
  getSortList (status?: number) {
    return new Promise(resolve => {
      sortPageApi({status}).then(e => {
        this.sortTreeList = e;
        resolve(e)
      })
    })
  }

  /**
   * 新增商品
   */
  handleRowAdd() {
    this.$router.push('./add-edit-shelves')
  }

  /**
   * 导出excel
   */
  handleExport() {
    shelvesProductExportApi(this.queryParam).then(e => {
      if (e) {
        exportFile(e, '货架信息.xlsx')
        Message.success("导出成功!")
      }
    })
  }

  /**
   * 切换状态
   */
  handleSwitch(row: any, val: number) {
    shelvesProductModifyStatusApi(row.id).then(e => {
      Message.success('操作成功!')
      this.getList()
    })
  }

  /**
   * 获取详情
   * @param id 订单id
   */
  getDetail(id: string, index: number) {
    shelvesProductDetailApi(id).then(e => {
     // @ts-ignore
      this.$refs.crudRef.rowView(e, index)
      // this.detailData = e
    })
  }

  /**
   * 打开修改排序
   */
  openEditSortDialog (row: any) {
    this.editSortForm.id  = row.id;
    this.editSortForm.sort = row.sort
    this.editSortDialog = true
  }

  /**
   * 确认修改排序
   */
  editSortEnter (done: Function, loading: Function) {
    if (!/^[1-9]\d{0,4}$/.test(this.editSortForm.sort)||parseInt(this.editSortForm.sort) > 99999) {
      loading()
      return Message.error("请输入排序1-99999")
    }
    shelvesProductModifySortingApi(this.editSortForm.id, parseInt(this.editSortForm.sort)).then(e => {
      if (e) {
        Message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  /**
   * 关闭修改排序
   */
  editSortClose() {
    this.editSortForm = {
      id: '',
      sort: ''
    }
  }
  /**
   * 打开编辑
   * @param row
   */
  async openEdit(row: any, $index: number) {
    this.$router.push(`./add-edit-shelves?id=${row.id}`)
  }

  getSpDataValues (str: string) {
    let json: {key: string, value: string}[];
    try {
      json = JSON.parse(str || '[]')
    } catch (e) {
      json = []
    }
    return json.map(item => item.value).join(';')
  }
  /**
   * *全网贡献值分成
   * @param res
   */
  setTypeTable(res: any) {
    this.typeTableData[0].value = res.consumerContribute;
    this.typeTableData[1].value = res.memberContribute;
    this.typeTableData[2].value = res.shareContribute;
    this.typeTableData[3].value = res.storeContribute;
    this.typeTableData[4].value = res.cityContribute;
    this.typeTableData[5].value = res.communityContribute;
  }
  openSkuDetails(row:any){
    if (row && row.productSkuContributeVo) {
      this.setTypeTable(row.productSkuContributeVo);
    }
    this.visibleDialog=true;
  }
  created() {
    this.getSortList()
    this.getList()
  }
}
