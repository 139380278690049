/**
 * @name: 商品管理-货架管理接口文件
 * @author:
 * @date: 2024-05-13 11:37
 * @description： 商品管理-货架管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IProduct, IProductParams, IProductSkuDto} from "@/apis/product/shelves/types";

/**
 * 查询
 * @param params
 */
export const shelvesProductPageApi = (params: IProductParams) => get<IPageRes<IProduct[]>>("/admin/product/query", params)

/***
 * 详情
 * @param id
 */
export const shelvesProductDetailApi = (id: string) => get<IProduct>("/admin/product/detail/" + id)

/***
 * 导出
 * @param id
 */
export const shelvesProductExportApi = (param: IProductParams) => get("/admin/product/export", param, "blob")
/***
 * 新增
 * @param data
 */
export const shelvesProductCreateApi = (data: IProduct) =>postJ("/admin/product/create",data)
/***
 * 修改
 * @param data
 */
export const shelvesProductModifyApi = (data: IProduct) =>postJ("/admin/product/modify",data)
/***
 * 商品管理-货架管理-新增自营商品-可分配社群贡献值
 * @param data
 */
export const shelvesProductContributionApi = (data: IProductSkuDto) =>postJ("/admin/product/calculateContribution",data)

/***
 * 修改排序
 * @param data
 */
export const shelvesProductModifySortingApi = (id: string, sort: number) =>postJ("/admin/product/modifySorting", {id,sort})

/**
 * 切换状态
 * @param id
 */
export const shelvesProductModifyStatusApi = (id: string) => postJ<IProduct>(`/admin/product/offline`, {id})
